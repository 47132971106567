





























































































































































































































import Embeddable from '@/components/Common/Embeddable.vue';
import AR from '@/components/UI/AR.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Embeddable,
    AR,
  },
})
export default class Services extends Vue {}
